<template>
    <div>
        <Survey v-if="survey" :survey="survey" />
    </div>
</template>

<script>
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-vue-ui';
import { computed, ref, watch, getCurrentInstance } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import darkTheme from '@/assets/surveyThemes/survey_theme_dark.json';
import lightTheme from '@/assets/surveyThemes/survey_theme_light.json';
import router from '@/router';
import { BButton } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const themes = {
    light: lightTheme,
    dark: darkTheme,
};

export default {
    components: {
        Survey,
        BButton,
    },
    setup() {
        const { skin } = useAppConfig();
        const surveySkin = computed(() => skin.value);

        const id = router.currentRoute.params.id;
        const item = ref(null);
        const survey = ref(null);

        const { proxy } = getCurrentInstance();

        proxy.$http.get(`/surveys/${id}`).then((res) => {
            item.value = res.data;
            const surveyJSON = item.value.body;
            survey.value = new Model(surveyJSON);
            survey.value.data = item.value.answers;
            survey.value.showPreviewBeforeComplete = 'showAllQuestions';
            survey.value.previewText = 'Submit';
            survey.value.showCompletedPage = false;
            survey.value.applyTheme(themes[surveySkin.value]);
            if (!item.value.isSubmitted) {
                survey.value.addNavigationItem({
                    id: 'sv-nav-save-progress',
                    title: 'Save',
                    visibleIndex: 35,
                    action: () => saveProgress(),
                    css: 'nav-button',
                    innerCss: 'sd-btn nav-input',
                });
            }
            survey.value.onCompleting.add(() => {
                submitSurvey();
            });
        }).catch((err) => {
            const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
            proxy.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Error',
                    text: errorMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        });


        watch(surveySkin, (newSkin) => {
            if (survey.value) {
                survey.value.applyTheme(themes[newSkin]);
            }
        });

        const saveProgress = () => {
            proxy.$http
                .put(`/surveys/${id}/answers`, { answers: survey.value.data })
                .then((response) => {
                    success('saved');
                    router.push({ name: 'prs-main' });
                })
                .catch((err) => {
                    console.error('Error saving progress', err);
                    const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Error saving progress.'
                    error(errorMessage);
                    router.push({ name: 'prs-main' });
                });
        };

        const submitSurvey = () => {
            proxy.$http
                .put(`/surveys/${id}/answers`, { answers: survey.value.data })
                .then(() => {
                    proxy.$http
                        .put(`/surveys/${id}/submit`)
                        .then(() => {
                            success('submitted');
                            router.push({ name: 'prs-main' });
                        })
                        .catch((err) => {
                            console.error('Error submitting survey', err);
                            error('Error submitting survey');
                            router.push({ name: 'prs-main' });
                        });
                })
                .catch((err) => {
                    console.error('Error saving progress before submitting', err);
                    const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Error saving progress before submitting.'
                    error(errorMessage);
                    router.push({ name: 'prs-main' });
                });
        };

        const success = (action) => {
            proxy.$swal({
                title: `Survey ${action} successfully!`,
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        };

        const error = (errorMessage) => {
            proxy.$swal({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        };

        return {
            surveySkin,
            survey,
            saveProgress,
            submitSurvey,
            success,
            error,
        };
    },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>